<template>
    <div class="vue-box">
        <div class="c-panel">
            <!-- 参数栏 -->
            <el-form :inline="true" size="mini">
                <el-form-item label="项目名称：">
                    <el-input v-model="p.name" placeholder="模糊查询"></el-input>
                </el-form-item>
                <el-form-item label="项目状态">
                    <input-enum enumName="projectStateEnum" v-model="p.state"></input-enum>
                </el-form-item>
                <el-form-item label="项目编码：">
                    <el-input v-model="p.code" placeholder="模糊查询"></el-input>
                </el-form-item>


                <el-form-item label="交付时间：">
                    <el-date-picker v-model="p.startFinishDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="开始日期"></el-date-picker>
                    -
                    <el-date-picker v-model="p.endFinishDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item style="min-width: 0px">
                    <el-button type="primary" icon="el-icon-search" @click="f5()">查询
                    </el-button>
                    <el-button type="primary" icon="el-icon-plus" @click="add">增加</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="c-title">数据列表</div> -->
            <el-table :data="dataList" size="mini">
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="项目名称" prop="name"></el-table-column>
                <el-table-column label="项目状态" prop="stateEnum"></el-table-column>
                <el-table-column label="项目编号" prop="code"></el-table-column>
                <el-table-column label="合同编号" prop="contactCode"></el-table-column>
                 <el-table-column label="联系手机" prop="mobile"></el-table-column>
                <el-table-column label="交付时间" prop="finishDate"></el-table-column>
                <el-table-column label="创建时间" sortable prop="createTime"></el-table-column>
                <el-table-column label="项目地点" prop="address"></el-table-column>

                <el-table-column prop="address" label="操作" width="220px">
                    <template slot-scope="s">
                        <el-button class="c-btn" type="success" icon="el-icon-edit" @click="show(s.row)">查看</el-button>
                        <el-button class="c-btn" type="primary" icon="el-icon-edit" @click="update(s.row)">修改
                        </el-button>
                        <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page"
                    :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]"
                    @current-change="f5(true)" @size-change="f5(true)">
                </el-pagination>
            </div>
        </div>

        <!-- 增改组件 -->
        <add-or-update ref="add-or-update"></add-or-update>
    </div>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
import addOrUpdate from "./add.vue";
export default {
    components: {
        addOrUpdate,
        inputEnum,
    },
    data() {
        return {
            p: {
                pageSize: 10,
                page: 1,
                name: "",
                state: "",
                code: "",
                startFinishDate: "",
                endFinishDate: "",
            },
            dataCount: 0,
            dataList: [],
        };
    },
    methods: {
        // 数据刷新
        f5: function () {
            this.sa.put("/project/listPage", this.p).then((res) => {
                this.dataList = res.data.content.map((item) => {
                    item.rowFilesFile = JSON.parse(item.rowFiles);
                    item.contactFilesFile = JSON.parse(item.contactFiles);
                    return item;
                });
                this.dataCount = res.data.totalElements;
            });
        },
        // 删除
        del: function (data) {
            this.sa.confirm(
                "是否删除，此操作不可撤销",
                function () {
                    this.sa.delete("/project/delete/" + data.id).then((res) => {
                        console.log(res);
                        this.sa.arrayDelete(this.dataList, data);
                        this.sa.ok(res.message);
                    });
                }.bind(this)
            );
        },
        //更新
        update(row) {
            this.sa_admin.navigateTo("project-add", row)
            //this.$refs["add-or-update"].open(row);
        },
        //添加
        add: function () {
            this.sa_admin.navigateTo("project-add")
            //this.$refs["add-or-update"].open(0);
        },
        show: function (row) {
            this.sa_admin.navigateTo("project-detail", row)
        }
    },
    created: function () {
        this.f5();
    },
};
</script>

<style>
</style>
