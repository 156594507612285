<style scoped>
</style>

<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">
                    <el-form-item label="项目名称2：" prop="name">
                        <el-input v-model="m.name"></el-input>
                    </el-form-item>
                    <el-form-item label="项目状态">
                        <input-enum enumName="projectStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                    <el-form-item label="项目编码：" prop="code">
                        <el-input v-model="m.code"></el-input>
                    </el-form-item>

                    <el-form-item label="联系人电话：" prop="mobile">
                        <el-input v-model="m.mobile"></el-input> 小程序登录使用
                    </el-form-item>

                    <el-form-item label="交付时间：" prop="finishDate">
                        <el-date-picker v-model="m.finishDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="交付时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="项目清单">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false"
                            :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_rowFiles"
                            :before-remove="remove_rowFiles" :file-list="m.rowFilesFile">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">上传项目清单</div>
                        </el-upload>
                    </el-form-item>
                     <el-form-item label="合同编号：" prop="contactCode">
                        <el-input v-model="m.contactCode"></el-input>
                    </el-form-item>
                    <el-form-item label="项目地点">
                        <el-input type="textarea" rows="2" placeholder="项目地点" v-model="m.address"></el-input>
                    </el-form-item>
                    <el-form-item label="合同附件">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false"
                            :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_contactFiles"
                            :before-remove="remove_contactFiles" :file-list="m.contactFilesFile">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">上传合同附件</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="mini" @click="ok('ruleForm')">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                name: [{ required: true, message: '请输入项目名称', trigger: 'blur' },],
                state: [{ type: 'number', message: '项目状态必须为数字类型', trigger: 'blur' }, { min: 0, max: 4, message: '状态不在有效区域', trigger: 'blur' },],
                code: [{ required: true, message: '请输入项目编码', trigger: 'blur' }, { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },],
                finishDate: [],
                rowFiles: [],
                address: [],
                contactFiles: [],
            },
            fileList: [],
        }
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data) {
                this.title = "修改 项目";
                data.rowFilesFile = JSON.parse(data.rowFiles);
                data.contactFilesFile = JSON.parse(data.contactFiles);
                this.m = data;
            } else {
                this.m = {
                    name: '',
                    state: 0,
                    code: '',
                    finishDate: '',
                    rowFiles: '',
                    address: '',
                    contactFiles: '',
                    contactFilesFile:[],
                    rowFilesFile:[]
                }
                this.title = "添加 项目";
            }
        },
        success_rowFiles(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.rowFilesFile) {
                this.m.rowFilesFile = [];
            } this.m.rowFilesFile.push(response.data);
            console.log(fileList);
        },
        remove_rowFiles(file, fileList) {
            this.m.rowFilesFile = fileList;
        },
        success_contactFiles(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.contactFilesFile) {
                this.m.contactFilesFile = [];
            } this.m.contactFilesFile.push(response.data);
            console.log(fileList);
        },
        remove_contactFiles(file, fileList) {
            this.m.contactFilesFile = fileList;
        },

        //提交项目信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.m.rowFiles = JSON.stringify(this.m.rowFilesFile.map(item => {
                        let a = {};
                        a.name = item.name;
                        a.url = item.url;
                        return a;
                    }));
                    this.m.contactFiles = JSON.stringify(this.m.contactFilesFile.map(item => {
                        let a = {};
                        a.name = item.name;
                        a.url = item.url;
                        return a;
                    }));
                    this.sa.post("/project/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {
    },
};
</script>